<template>
  <div>
    <!-- Filter input and Search button -->
    <div class="input-group mb-3 inner-addon">
      <!-- Dropdown type of filter -->
      <button
        class="btn btn-outline-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ filter.type }}
      </button>
      <ul class="dropdown-menu">
        <li v-for="(type, index) in filterTypes" :key="index">
          <a
            class="dropdown-item"
            @click="
              filter.type = type;
              sendToParent();
            "
            >{{ type }}</a
          >
        </li>
      </ul>

      <!-- Filter input -->
      <textarea
        class="form-control"
        placeholder="Enter filter text"
        v-model="filter[filter.type]"
        @keydown.enter="sendToParent"
        rows="1"
        ref="textarea"
        @focus="resize"
        @keyup="resize"
      ></textarea>
      <!-- Add to dynamically refresh list when typing filter -->
      <!-- v-on:input="sendToParent()" -->

      <!-- Clear filter button -->
      <div type="button" class="addon addon-right" @click="clearFilter()">
        <i class="bi bi-x"></i>
      </div>

      <!-- Filter history button -->
      <FilterHistory
        :value="filterUpdate"
        :historyType="['historyUsers']"
        name="users"
        @selectedEntry="(selectedEntry) => getHistory(selectedEntry)"
      />

      <!-- Search button -->
      <button class="btn btn-secondary" @click="sendToParent()">
        <i class="bi bi-search"></i>
      </button>
    </div>

    <!-- Help -->
    <UsersFilterHelp :type="filter.type" class="mb-2" />

    <!-- Actions -->
    <UsersAction :filter="marshalFilter()" :game="game" class="mt-3" />
  </div>
</template>

<script>
import UsersFilterHelp from "./UsersFilterHelp.vue";
import UsersAction from "./UsersAction.vue";
import FilterHistory from "./FilterHistory.vue";

const usersFilterName = "usersFilter";

export default {
  name: "UsersFilterInventory",

  components: {
    UsersFilterHelp,
    UsersAction,
    FilterHistory,
  },

  props: {
    game: String,
  },

  data() {
    return {
      usersList: [],
      previous: "",
      filter: {
        Items: "",
        Armor: "",
        Storage: "",
        All: "",
        type: "All",
      },
      filterTypes: ["All", "Items", "Armor", "Storage"],
      filterUpdate: "",
    };
  },
  emmits: ["filter"],

  mounted() {
    this.getFilter();
    this.whenLogin.then(this.sendToParent);
  },

  methods: {
    resize() {
      const { textarea } = this.$refs;
      textarea.style.height = textarea.scrollHeight + "px";
    },

    /**
     * sendToParent - return event filter with filter.type and filter parameters
     * to parent component.
     */
    sendToParent() {
      if (this.filter[this.filter.type]) {
        this.filterUpdate =
          this.filter.type + ": " + this.filter[this.filter.type];
      }
      this.setFilter();
      this.$emit("filter", this.marshalFilter());
    },

    /**
     * marshalFilter - Return filter type and filter parameters in one string.
     */
    marshalFilter() {
      const type = this.filter.type;
      return type + ": " + this.filter[type];
    },

    //** Load filter set from local storage */
    getFilter() {
      let usersFilter = localStorage.getItem(usersFilterName);
      if (usersFilter) {
        let filter = JSON.parse(usersFilter);
        if (filter.inventory) {
          this.filter = filter.inventory;
        }
      }
    },

    /** Save filter set to local storage */
    setFilter() {
      let filter = {};
      let usersFilter = localStorage.getItem(usersFilterName);
      if (usersFilter) {
        // If usersFilter is set, parse it and set the game.
        filter = JSON.parse(usersFilter);
      } else {
        // If usersFilter is not set, create a new object.
        filter = {};
      }
      filter.inventory = this.filter;
      localStorage.setItem(usersFilterName, JSON.stringify(filter));
    },

    clearFilter() {
      this.filter.Items = "";
      this.filter.Armor = "";
      this.filter.Storage = "";
      this.filter.All = "";
      this.sendToParent();
    },

    getHistory(history) {
      let fltr = history.split(": ");
      this.filter.type = fltr[0];
      this.filter[this.filter.type] = fltr[1];
      this.sendToParent();
    },
  },
};
</script>

<style scoped>
textarea {
  padding-right: 60px;
}
</style>
