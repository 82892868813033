<template>
  <div>
    <!-- Button -->
    <button
      v-if="btn"
      class="btn btn-outline-secondary"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span>{{ selectedEntry }}</span>
      <i class="bi bi-clock-history"></i>
    </button>

    <!-- Icon -->
    <div
      v-else
      class="addon addon-right"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="bi bi-clock-history"></i>
    </div>

    <!-- List of history items -->
    <ul class="card dropdown-menu">
      <!-- Header -->
      <div class="card-header row">
        <!-- Title -->
        <h3 class="col mb-0 p-0 ps-2 dropdown-item-text text_no_wrap">
          Filter history
        </h3>
        <!-- Close button -->
        <div class="col p-1 text-end">
          <button type="button" class="btn-close" aria-label="Close"></button>
        </div>
      </div>

      <!-- History items -->
      <li v-for="(entry, idx) in combinedData" :key="idx">
        <!-- Divider or empty space -->
        <hr v-if="idx > 0" class="dropdown-divider" />
        <div v-else class="mb-2"></div>

        <!-- Filter title input -->
        <input
          v-if="entry.showTitleInput"
          type="text"
          v-model="entry.title"
          placeholder="Add title"
          class="form-control"
          @keyup.enter="addHistoryTitle(idx, entry.title)"
        />

        <!-- Filter content -->
        <a
          class="dropdown-item"
          @click="
            selectedEntry;
            $emit('selectedEntry', entry.filter);
          "
        >
          <!-- Filter title -->
          <span v-if="entry.title && !entry.showTitleInput" class="title">
            {{ entry.title }}<br />
          </span>

          <!-- Filter text -->
          <span
            :class="
              entry.title || entry.showTitleInput ? 'small text-secondary' : ''
            "
          >
            {{ entry.filter }}</span
          >
        </a>

        <!-- Delete entry button -->
        <button class="btn btn-del" @click.stop="del(idx)">
          <i class="bi bi-trash"></i>
        </button>

        <!-- Rename title button -->
        <button
          v-if="!entry.showTitleInput"
          class="btn btn-rename m-2"
          @click.stop="this.history[idx].showTitleInput = true"
        >
          <i class="bi bi-pen"></i>
        </button>

        <!-- Confirm title button -->
        <button
          v-else
          class="btn btn-confirm m-2 text-success"
          @click.stop="addHistoryTitle(idx, entry.title)"
        >
          <i class="bi bi-check-lg"></i>
        </button>

        <!-- Add to Cloud button -->
        <button
          v-if="entry.data === 0"
          class="btn m-0"
          @click.stop="sendCmdHistoryFilterSet(idx)"
        >
          <i class="bi bi-cloud-plus h5"></i>
        </button>

        <!-- If entry exists in Cloud show this icon -->
        <button v-if="entry.data === 1" class="btn m-0" disabled>
          <i class="text-success bi bi-cloud-check h5"></i>
        </button>
      </li>

      <!-- Database entries header -->
      <div class="card-header row">
        <!-- Title -->
        <h3 class="col mb-0 p-0 ps-2 dropdown-item-text text_no_wrap">
          Cloud entries
          <div class="small text-secondary mt-1">
            <span
              >filter histories saved in the cloud will be displayed here.</span
            >
          </div>
        </h3>
      </div>

      <!-- History items from database -->
      <a v-for="(item, index) in historyDB" :key="index">
        <li v-for="(history, idx) in item.data" :key="idx">
          <div v-if="shouldShowCloudEntries(history.historyType)">
            <hr v-if="idx > 0" class="dropdown-divider" />
            <div v-else class="mb-2"></div>

            <!-- History items database content -->
            <a
              class="dropdown-item"
              @click="
                selectedEntry;
                $emit('selectedEntry', history.filter);
              "
            >
              <!-- Filter title -->
              <span v-if="history.title" class="title">
                {{ history.title }}
                <i class="bi bi-cloud"></i><br />
              </span>

              <!-- Filter text -->
              <span
                :class="
                  history.title || !history.filter ? 'small text-secondary' : ''
                "
              >
                {{ history.filter }}
                <i v-if="!history.title" class="bi bi-cloud"></i>
              </span>
            </a>

            <!-- Delete entry button -->
            <button
              class="btn btn-del"
              @click.stop="delCloudHistoryFilter(idx)"
            >
              <i class="bi bi-cloud-minus h5"></i>
            </button>
          </div>
        </li>
      </a>
    </ul>
  </div>
</template>

<script>
const prefix = "historyFilter-";
const cmdHistoryFilterGet = "history.filter.get";
const cmdHistoryFilterSet = "history.filter.set";
const cmdHistoryFilterDel = "history.filter.del";
const cmdHistoryFilterCheck = "history.filter.check";

export default {
  name: "FilterHistory",

  emits: ["selectedEntry", "selectedEntryDB"],

  props: {
    btn: Boolean,
    name: String,
    value: String,
    historyType: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      history: [{ filter: "", title: "" }],
      selectedEntry: "",
      historyDB: {},
      checkEntriesDB: [],
    };
  },

  computed: {
    combinedData() {
      const dataArray =
        this.checkEntriesDB.find((item) => item.historyType)?.data || [];

      return this.history.map((user, index) => {
        const dataValue = dataArray[index] || 0;
        return { ...user, data: dataValue };
      });
    },
  },

  mounted() {
    let that = this;

    this.get();
    this.add(this.value);

    // Add reader
    this.reader = this.teoweb.addReader(function (gw, data) {
      // Check error
      if (gw.err) {
        that.error = "Error: " + gw.err;
        return;
      }
      that.error = "";

      // Process answer
      switch (gw.command) {
        // Process answer to cmdHistoryFilterGet command
        case cmdHistoryFilterGet: {
          that.webasmReady.then(() => {
            // data = window.decompressData(data);
            that.historyDB = JSON.parse(data);
          });
          that.cmdCheckCurrentEntries();
          break;
        }

        // Process answer to cmdHistoryFilterSet command
        case cmdHistoryFilterSet: {
          that.cmdHistoryFilterGet();
          break;
        }

        // Process answer to cmdHistoryFilterDel command
        case cmdHistoryFilterDel: {
          that.cmdHistoryFilterGet();
          break;
        }

        // Process answer to cmdHistoryFilterCheck command
        case cmdHistoryFilterCheck: {
          that.webasmReady.then(() => {
            // data = window.decompressData(data);
            that.checkEntriesDB = JSON.parse(data);
          });
          break;
        }
      }
    });
    // Send 'cmdHistoryFilter' command to WebRTC server
    this.whenLogin.then(() => {
      that.cmdHistoryFilterGet();
      that.cmdCheckCurrentEntries();
    });
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /**
     * Method add adds filter to history and localstorage
     *
     * @param {string} str - filter string
     *
     * If str is empty, the method does nothing and returns.
     *
     * If str is not empty, the method checks if the filter is already in
     * history and moves it to the top. Otherwise, it adds a new filter to the
     * top of the history.
     *
     * After adding or moving the filter, the method calls set() to save the
     * history to localstorage.
     */
    add(str) {
      if (!str) {
        return;
      }

      // Check if filter is already in history and move it to the top.
      // Otherwise add it to the top.
      const idx = this.history.findIndex((t) => t.filter == str);
      if (idx >= 0) {
        // Move existing filter to the top
        let el = this.history[idx];
        this.history.splice(idx, 1);
        this.history.unshift(el);
      } else {
        // Add new filter to the top
        this.history.unshift({ filter: str, title: "" });
      }

      // Save the history to localstorage
      this.set();
    },

    // Method get reads history from localstorage
    get() {
      const filterStr = localStorage.getItem(prefix + this.name);
      this.history = [];
      if (filterStr) {
        this.history = JSON.parse(filterStr);
      }
    },

    // Method del deletes filter from history and localstorage
    del(idx) {
      if (idx >= 0) {
        this.history.splice(idx, 1);
        this.set();
      }
    },

    // Method addHistoryTitle adds title to entry from localStorage
    addHistoryTitle(idx, title) {
      this.history[idx] = {
        filter: this.history[idx].filter,
        title: title,
      };

      // Save history to localstorage
      this.set();
    },

    // Method set saves history to storage
    set() {
      localStorage.setItem(prefix + this.name, JSON.stringify(this.history));
      this.cmdCheckCurrentEntries();
    },

    // sendCmdHistoryFilterSet writes entry to database by idx
    sendCmdHistoryFilterSet(idx) {
      this.teoweb.sendCmd(
        cmdHistoryFilterSet,
        JSON.stringify({
          [this.historyType]: this.history[idx],
        })
      );
    },

    // delCloudHistoryFilter deletes entry from database by idx and table ID
    delCloudHistoryFilter(idx) {
      this.teoweb.sendCmd(cmdHistoryFilterDel, this.historyDB[0].data[idx].ID);
    },

    // cmdHistoryFilterCheck checks all filter history entries in database
    cmdCheckCurrentEntries() {
      this.teoweb.sendCmd(
        cmdHistoryFilterCheck,
        JSON.stringify({
          [this.historyType]: this.history,
        })
      );
    },

    // cmdHistoryFilterGet sends teoweb command to receive existing entries in database
    cmdHistoryFilterGet() {
      this.teoweb.sendCmd(
        cmdHistoryFilterGet,
        JSON.stringify({
          [this.historyType]: this.history,
        })
      );
    },

    // shouldShowCloudEntries shows records from the database on the page
    // depending on the passed props
    shouldShowCloudEntries(historyType) {
      return this.historyType.includes(historyType);
    },
  },

  watch: {
    value: function () {
      this.add(this.value);
    },
  },
};
</script>

<style scoped>
.btn {
  border: none;
  padding: 0;
  opacity: 0.5;
  margin-left: 15px;
}

.btn:hover {
  opacity: 1;
}

.addon-right {
  right: 75px;
}

.card.dropdown-menu {
  display: none;
}
.card.dropdown-menu.show {
  display: block;
}

.dropdown-menu {
  padding-top: 0;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-item {
  text-wrap: auto;
}

.card-header {
  position: sticky;
  top: 0;
  z-index: 1020;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
}

.small {
  font-size: small;
}

.title {
  font-weight: bold;
}

.text_no_wrap {
  white-space: nowrap;
}
</style>
