<template>
  <span v-if="!users.lenght" class="spinner-grow" role="status"> </span>
  <span v-else>{{ users.lenght }}</span>
</template>

<script>
const allGames = "All games";
const cmdLenght = "users.length";
const cmdLenghtGame = "users.length.game";

export default {
  name: "UsersLength",

  props: {
    game: String,
  },

  data() {
    return {
      users: {
        lenght: 0, // Number of users
      },
    };
  },

  mounted() {
    this.getUsersLenght(this.game);
  },

  watch: {
    game() {
      this.getUsersLenght(this.game);
    },
  },

  methods: {
    /**
     * getUsersLenght - Sends a command to the WebRTC server to get the number of users.
     * @param {string} game - The game name to get the number of users for. If empty or
     *   "All games", gets the number of users for all games.
     */
    getUsersLenght(game) {
      let that = this;
      this.users.lenght = "";

      // All game mode
      if (game == "" || game == allGames) {
        // Send cmdLenght command with parameter name to WebRTC server
        // to get the number of users for all games.
        this.sendCmd(cmdLenght).then(
          // Process success answer
          (data) => (that.users.lenght = JSON.parse(data)),
          // Process an error
          (err) => console.error("cmdLenght error:", err)
        );

        return;
      }

      // Game mode
      // Send cmdLenghtGame command with parameter name to WebRTC server
      // to get the number of users for the specified game.
      this.sendCmd(cmdLenghtGame + "/" + game).then(
        // Process success answer
        (data) => (that.users.lenght = JSON.parse(data)),
        // Process an error
        (err) => console.error("cmdLenght error:", err)
      );
    },
  },
};
</script>

<style scoped>
.spinner-grow {
  width: 15px;
  height: 15px;
}
</style>
